import BasicSection from "components/BasicSection";
import Layout from "components/Layout";
import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";

interface DataProps {
    allMdx: {
        nodes: DataPropsNode[];
    };
}

interface DataPropsNode {
    frontmatter: {
        title: string;
        description: string;
    };
    fields: {
        slug: string;
        sourceInstanceName: string;
    };
    id: string;
    excerpt: string;
}

const Compare: React.FC<PageProps<DataProps>> = ({ path, data }) => {
    const {
        allMdx: { nodes },
    } = data;

    return (
        <Layout title="Ente - Comparison" path={path}>
            <BasicSection>
                <BasicSection.Header>
                    <BasicSection.Heading>Comparison</BasicSection.Heading>
                </BasicSection.Header>

                <BasicSection.Body>
                    <ComparisonList {...{ nodes }} />
                </BasicSection.Body>
            </BasicSection>
        </Layout>
    );
};

export default Compare;

export const query = graphql`
    query {
        allMdx(
            filter: { fields: { sourceInstanceName: { eq: "compare" } } }
            sort: { fields: frontmatter___index, order: ASC }
        ) {
            nodes {
                frontmatter {
                    title
                    description
                    date(formatString: "MMMM D, YYYY")
                }
                fields {
                    slug
                    sourceInstanceName
                }
                id
                excerpt(pruneLength: 90)
            }
        }
    }
`;

const ComparisonList: React.FC<{ nodes: DataPropsNode[] }> = ({ nodes }) => {
    return (
        <div role="list" className="d-flex flex-column gap-4 mx-md-5">
            {nodes.map((node) => (
                <ComparisonCard key={node.id} {...{ node }} />
            ))}
        </div>
    );
};

interface ComparisonCardPros {
    node: DataPropsNode;
}

const ComparisonCard: React.FC<ComparisonCardPros> = ({ node }) => {
    const {
        frontmatter: { title, description },
        fields: { slug, sourceInstanceName },
        excerpt,
    } = node;

    const path = `/${sourceInstanceName}${slug}`;
    return (
        <div role="listitem" data-sal="slide-up" data-sal-duration="600">
            <ComparisonLink to={path} state={{ fromIndex: true }}>
                <div className="card bg-elevated position-relative">
                    <CardContents className="card-body mx-3 my-2 my-md-3">
                        <h5 className="card-title mt-1">{title}</h5>
                        <p className="card-text text-muted mt-md-3">
                            {description || excerpt}
                        </p>
                    </CardContents>
                </div>
            </ComparisonLink>
        </div>
    );
};

const ComparisonLink = styled(Link)`
    text-decoration: none;

    &&:hover .card {
        background-color: var(--color-elevated-bg-hover);
        border-color: var(--bs-border-color);
    }
`;

const CardContents = styled.div`
    z-index: 1;
`;
